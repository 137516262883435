<template>
  <!-- 待支付详情 -->
  <div style="width: 100%; position: relative">
    <div class="common-gaine" style="padding-top: 100px">
      <div class="common-plate-title">
        <span></span>
        <i
          class="iconfont icon-fanhui1"
          :title="$trans('返回上一页', '返回上一页')"
          @click="$router.history.go(-1)"
        ></i>
      </div>
      <!-- <div v-if="status == 1"> -->
      <AddressItem
        :data="address"
        class="address-box"
        :onlyshow="status == 1 ? false : true"
      />
      <!-- </div> -->

      <div class="no-payment-component">
        <ShopInfo
          :data="item"
          v-for="(item, index) in goodsDetailVoList"
          :key="index"
        />
        <div class="no-payment-bottom">
          <div class="no-payment-subtotal flex-bet-cen">
            <span>小计</span>
            <div class="object__price" style="color: #2a9dcc">
              <div style="font-style: normal">￥</div>
              <div class="object__int">{{ sumMoney | product_int }}</div>
              <div class="object__float">{{ sumMoney | product_float }}</div>
            </div>
          </div>
        </div>
        <div class="express-info-wrap" style="margin-top: 30px">
          <div class="no-payment-order flex-bet-cen">
            <span>{{ $trans('开具发票', '开具发票') }}</span>
            <div class="no-payment-serial flex-cen">
              <span>{{
                data.orderInfoVoList &&
                data.orderInfoVoList[0] &&
                data.orderInfoVoList[0].invoiceId
                  ? $trans('已开具发票', '已开具发票')
                  : $trans('未开具发票', '未开具发票')
              }}</span>
              <i class="iconfont icon-xiangyou1"></i>
            </div>
          </div>
          <div class="no-payment-order flex-bet-cen">
            <span>{{ $trans('优惠券', '优惠券') }}</span>
            <div class="no-payment-serial flex-cen">
              <span>{{ couponVoPay }}</span>
              <i class="iconfont icon-xiangyou1"></i>
            </div>
          </div>
          <div class="no-payment-order flex-bet-cen">
            <span>{{ $trans('配送服务', '配送服务') }}</span>
            <div class="no-payment-serial flex-cen" @click="showMail = true">
              <span>{{ extraCharge }}</span>
              <i class="iconfont icon-xiangyou1"></i>
            </div>
          </div>
          <div class="no-payment-order flex-bet-cen">
            <span>{{ $trans('支付方式', '支付方式') }}</span>
            <el-dropdown @command="payHandleCommand" trigger="click">
              <span class="el-dropdown-link" v-if="status == 1">
                {{ $trans(payText, payText)
                }}<i class="iconfont icon-xiangyou1"></i>
              </span>
              <span class="el-dropdown-link" v-else>
                {{ $trans(payText, payText)
                }}<i class="iconfont icon-xiangyou1"></i>
              </span>
              <el-dropdown-menu slot="dropdown" v-if="status == 1">
                <el-dropdown-item
                  :command="item"
                  v-for="(item, index) in paySelectList"
                  :key="index"
                >
                  {{ $trans(item, item) }}</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <div class="no-payment-bottom">
          <div class="no-payment-subtotal flex-bet-cen">
            <span>{{ $trans('实付', '实付') }}</span>
            <div class="object__price" style="color: #2a9dcc">
              <div style="font-style: normal">￥</div>
              <div class="object__int">{{ data.totalCost | product_int }}</div>
              <div class="object__float">
                {{ data.totalCost | product_float }}
              </div>
            </div>
          </div>
        </div>
        <div class="btn-fill btn" v-if="status == 1" @click="getOrderPay">
          {{ $trans('立即支付', '立即支付') }}
        </div>
        <div class="shop-detail-mask" v-show="showMail">
          <div class="mask-content">
            <div class="mask-content-header font-medium">
              {{ $trans('配送服务', '配送服务') }}
            </div>
            <div class="mask-category__wrap">
              <div
                class="mask-list-item"
                v-for="(item, index) in shangsfOrderSkuTemplate"
                :key="index"
              >
                <div
                  v-for="(ite, ind) in item.shangsfSkuTemplateItemList"
                  :key="ind"
                  class="flex-bet-cen"
                >
                  <span>{{ ite.stuffName }}</span>
                  <span class="mask__price"
                    >￥{{ ite.stuffValue + '*' + item.count }}</span
                  >
                </div>
              </div>
            </div>
            <div class="confirm-button btn-fill" @click="showMail = false">
              {{ $trans('确定', '确定') }}
            </div>
            <div class="mask-delete" @click="showMail = false">×</div>
          </div>
        </div>
      </div>
    </div>
    <PaymentItem ref="payMethod" :batchNo="batchNo" />
  </div>
</template>
<script>
import AddressItem from '@/components/address/Address.vue'
import ShopInfo from '@/components/order/OrderItem.vue'
import { getPayDetail } from '@/util/js/api.js'
import PaymentItem from '@/components/payment/wxScan.vue'
export default {
  name: '',
  data() {
    return {
      payText: '请选择支付方式', // 支付方式
      payAllList: [
        // 支付列表
        '微信',
        '支付宝',
      ],
      paySelectList: [], // 渲染支付列表
      discountList: [
        {
          startTime: '2022.04.05',
          endTime: '2022.05.05',
          discount: 8.5,
          describe: '本优惠券仅适用于XXX作品使用',
          id: '001',
          type: 0,
        },
        {
          startTime: '2022.04.05',
          endTime: '2022.05.05',
          discount: 8.5,
          describe: '本优惠券仅适用于XXX作品使用',
          id: '002',
          type: 1,
        },
      ],
      address: {}, // 地址
      payMethodsList: [
        {
          icon: '#icon-zhifubao',
          text: '支付宝',
          id: '001',
        },
        {
          icon: '#icon-weixin1',
          text: '微信',
          id: '002',
        },
      ],
      showMail: false,
      showDiscount: false,
      payMethods: false,
      payMethod: '',
      goodsDetailVoList: [], // 商品列表
      QRCode: '', // 微信二维码地址
      dialogFormVisible: false, // 二维码弹出层
      batchNo: '', // 订单信息
      data: {},
      shangsfOrderSkuTemplate: [],
      status: '',
    }
  },
  components: {
    AddressItem,
    ShopInfo,
    PaymentItem,
  },
  methods: {
    init() {
      this.status = this.$route.query.status
      this.paySelectList = JSON.parse(JSON.stringify(this.payAllList))
      this.address = this.$route.query.address
        ? JSON.parse(this.$route.query.address)
        : {}
      this.batchNo = this.$route.query.batchNo
      this.getNotPayDetail()
    },
    // 支付方式下拉
    payHandleCommand(command) {
      this.payText = command
      this.paySelectList = JSON.parse(JSON.stringify(this.payAllList))
      let i = this.paySelectList.findIndex((item) => item == command)
      this.paySelectList.splice(i, 1)
    },
    // 获取未支付订单信息
    getNotPayDetail() {
      let params = {
        userId: this.$store.state.userId,
        status: this.$route.query.status,
        batchNo: this.$route.query.batchNo,
      }
      getPayDetail(params).then((res) => {
        this.goodsDetailVoList = res.data.orderInfoVoList
          ? JSON.parse(JSON.stringify(res.data.orderInfoVoList))
          : []
        this.address = res.data.shangsfAddress
          ? JSON.parse(JSON.stringify(res.data.shangsfAddress))
          : []
        this.data = JSON.parse(JSON.stringify(res.data))
        this.shangsfOrderSkuTemplate = res.data.orderInfoVoList
          ? JSON.parse(JSON.stringify(res.data.orderInfoVoList))
          : []

        if (
          this.status != 1 &&
          this.goodsDetailVoList &&
          this.goodsDetailVoList.length > 0
        ) {
          if (this.goodsDetailVoList[0].payType) {
            this.payText =
              this.goodsDetailVoList[0].payType == 1 ? '微信' : '支付宝'
          } else {
            this.payText = ''
          }
        }
      })
    },
    // 支付
    getOrderPay() {
      const obj = this.getUserInfo.call(this, 'user')
      if (obj && obj['status'] != 3) {
        this.$message.warning(this.$trans('请先完成实名', '请先完成实名'))
        this.$router.history.push('/setting/authentication')
        return
      }

      if (this.payText == '请选择支付方式') {
        this.$message.warning(this.$trans('请选择支付方式', '请选择支付方式'))
        return
      }
      let param = {
        batchNo: this.batchNo,
        payType: this.payText == '支付宝' ? 2 : 1,
        addressId: this.address.id,
      }
      this.$refs.payMethod.paymentHandle(param)
    },
  },
  mounted() {
    this.init()
  },
  computed: {
    sumMoney() {
      return this.goodsDetailVoList.length > 0
        ? this.goodsDetailVoList.reduce(
            (a, b) => a + parseInt(b.count) * b.price,
            0
          )
        : 0
    },
    // actualSumMoney(){
    //     console.log(this.sumMoney * Number(this.discountObj.text) + this.serveCost);
    //     return this.discountObj.type == 0 ?  this.sumMoney * Number(this.discountObj.text) + this.serveCost : this.sumMoney - Number(this.discountObj.text) + this.serveCost
    // },
    // 使用优惠券
    couponVoPay() {
      if (
        this.data &&
        this.data.orderInfoVoList &&
        this.data.orderInfoVoList.length > 0
      ) {
        if (this.data.orderInfoVoList[0].couponType == 11) {
          return (
            `${this.$trans('全场', '全场')} ` +
            this.data.orderInfoVoList[0].couponValue * 10 +
            ` ${this.$trans('折', '折')}`
          )
        } else if (this.data.orderInfoVoList[0].couponType == 22) {
          return (
            `${this.$trans('全场减', '全场减')} ` +
            this.data.orderInfoVoList[0].couponValue +
            ` ${this.$trans('元', '元')}`
          )
        } else if (this.data.orderInfoVoList[0].couponType == 33) {
          return (
            `${this.$trans('全场满', '全场满')} ` +
            this.data.orderInfoVoList[0].couponConditionAmount +
            ` ${this.$trans('元打', '元打')} ` +
            this.data.orderInfoVoList[0].couponValue * 10 +
            ` ${this.$trans('折', '折')}`
          )
        } else if (this.data.orderInfoVoList[0].couponType == 44) {
          return (
            `${this.$trans('全场满', '全场满')} ` +
            this.data.orderInfoVoList[0].couponConditionAmount +
            ` ${this.$trans('元减', '元减')} ` +
            this.data.orderInfoVoList[0].couponValue +
            ` ${this.$trans('元', '元')}`
          )
        } else if (this.data.orderInfoVoList[0].couponType == 55) {
          return (
            `${this.$trans('部分商品', '部分商品')} ` +
            this.data.orderInfoVoList[0].couponValue * 10 +
            ` ${this.$trans('折', '折')}`
          )
        } else if (this.data.orderInfoVoList[0].couponType == 66) {
          return (
            `${this.$trans('部分商品减', '部分商品减')} ` +
            this.data.orderInfoVoList[0].couponValue +
            ` ${this.$trans('元', '元')}`
          )
        } else if (this.data.orderInfoVoList[0].couponType == 77) {
          return (
            `${this.$trans('部分商品满', '部分商品满')} ` +
            this.data.orderInfoVoList[0].couponConditionAmount +
            ` ${this.$trans('元打', '元打')} ` +
            this.data.orderInfoVoList[0].couponValue * 10 +
            ` ${this.$trans('折', '折')}`
          )
        } else if (this.data.orderInfoVoList[0].couponType == 88) {
          return (
            `${this.$trans('部分商品满', '部分商品满')} ` +
            this.data.orderInfoVoList[0].couponConditionAmount +
            ` ${this.$trans('元减', '元减')} ` +
            this.data.orderInfoVoList[0].couponValue +
            ` ${this.$trans('元', '元')}`
          )
        }
        return this.$trans('暂未使用优惠券', '暂未使用优惠券')
      } else {
        return this.$trans('暂未使用优惠券', '暂未使用优惠券')
      }
    },
    // 额外费用
    extraCharge() {
      if (JSON.stringify(this.shangsfOrderSkuTemplate) == '[]') {
        return 0
      }
      let arr = JSON.parse(JSON.stringify(this.shangsfOrderSkuTemplate))
      if (JSON.stringify(arr) == '[]') {
        return 0
      } else {
        return (
          parseInt(
            arr.reduce((a, b) => {
              return (
                a +
                parseInt(b.count) *
                  b.shangsfOrderSkuTemplate.reduce(
                    (c, d) => c + d.stuffValue,
                    0
                  )
              )
            }, 0) * 100
          ) / 100
        )
      }
    },
  },
}
</script>
<style scoped>
.address-box {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 40px;
}
.btn {
  width: 400px;
  margin: 0 auto;
}
.btn-fill {
  margin-top: 80px;
  cursor: pointer;
}
.btn-empty {
  margin-top: 20px;
}
.no-payment-component {
  padding: 21px 21px 15px 21px;
  background: #fdfdfd;
  border: 1px solid #dfdfdf;
  margin-bottom: 14px;
  box-shadow: 2px 2px 10px #dfdfdf;
}
.no-payment-component:last-of-type {
  margin-bottom: 0;
}
.no-payment-top {
  padding-bottom: 30px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.no-payment-bottom {
  padding-top: 20px;
  border-top: 1px solid #dfdfdf;
}
.no-payment-left {
  display: flex;
  align-items: flex-start;
}
.payment-info {
  margin-left: 30px;
}
.payment-title {
  font-size: 16px;
  font-family: 'myCoolFontMedium';
  margin-bottom: 10px;
}
.payment-author {
  font-size: 16px;
  color: #595959;
  transform: scale(0.8);
  transform-origin: -30% -20%;
}
.no-payment-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.payment-number {
  width: 40px;
  height: 40px;
  background: #dfdfdf;
  border-radius: 2px;
  line-height: 40px;
  text-align: center;
  font-size: 20px;
  transform: scale(0.5);
}
.no-payment-subtotal {
  font-size: 16px;
  font-family: 'myCoolFontMedium';
  margin-bottom: 24px;
}
.no-payment-order {
  font-family: 'myCoolFontRegular';
  color: #666666;
  margin-bottom: 50px;
  font-size: 12px;
}
.no-payment-oper {
  justify-content: flex-end;
  transform-origin: right;
  transform: scale(0.6);
}
.no-payment-oper .btn-wrap {
  width: 120px;
  height: 60px;
  border-radius: 7px;
  margin-right: 20px;
  cursor: pointer;
  border: 1px solid #dfdfdf;
  color: #999999;
  font-size: 16px;
  flex-shrink: 0;
}
.no-payment-oper .btn-wrap.btn-fill {
  border: none;
  color: #ffffff;
}
.no-payment-oper .btn-wrap:last-child {
  margin-right: 0;
}
.no-payment-select {
  width: 60px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f4f4f4;
  border-radius: 4px;
}
.no-payment-select input {
  margin-right: 10px;
}
.border-iframe-outer {
  display: inline-block;
  border: 1px solid #111111;
  transition: all 0.5s;
  box-shadow: 3px 3px 3px #999999;
}
.border-iframe-inner {
  border: 1px solid #ffffff;
}
img {
  max-width: 40px;
  max-height: 40px;
  width: auto;
  height: auto;
  display: block;
}
.select-item {
  width: 16px;
  height: 16px;
  margin-top: 30px;
  margin-right: 20px;
}
.express-info-wrap {
  margin-top: 50px;
  padding: 7px 0px;
  margin-bottom: 50px;
}
.express-info-key {
  font-size: 14px;
  color: #666666;
  line-height: 50px;
  font-family: 'myCoolFontRegular';
}
.express-info-value {
  font-size: 14px;
  color: #666666;
  line-height: 25px;
  font-family: 'myCoolFontRegular';
}
.shop-detail-mask {
  position: fixed;
  left: 25%;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 1000;
}
.mask-content {
  background: #ffffff;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 700px;
  height: 700px;
  padding: 42px;
}
.mask-delete {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}
.mask-content-header {
  font-size: 24px;
  text-align: center;
  margin-bottom: 40px;
}
.mask-category__wrap {
  height: calc(700px - 100px - 123px - 90px);
  overflow-y: scroll;
  font-size: 24px;
}
.confirm-button {
  margin: 50px auto;
  width: 350px;
}
.mask-list-item {
  font-family: 'myCoolFontMedium';
  color: 24px;
  line-height: 75px;
}
.discount-item {
  background: url('../../../assets/img/icon/back01.png');
  margin: 12px 12px 0 60px;
  height: 110px;
  width: 400px;
  cursor: pointer;
}
.discount-img-wrap {
  height: 110px;
  width: 110px;
  flex-shrink: 0;
}
.discount-content-wrap {
  padding: 30px 25px 15px 10px;
  height: 100%;
  flex-grow: 1;
}
.discount-content-top {
  align-items: flex-end;
}
.discount-content-title {
  font-size: 12px;
  margin-bottom: 8px;
}
.discount-content-time {
  color: #8f8f8f;
  font-size: 16px;
  transform-origin: left;
  transform: scale(0.5);
}
.discount-content-right {
  align-items: flex-end;
  color: #30a0cd;
}
.discount-content-int {
  font-size: 24px;
}
.discount-content-tip {
  font-size: 15px;
}
.discount-content-hr {
  margin-top: 15px;
  width: 100%;
  height: 1px;
  background: #dddddd;
  margin-bottom: 10px;
}
.discount-content-describe {
  transform-origin: left;
  font-size: 12px;
  transform: scale(0.6);
  color: #8f8f8f;
}
.immediate-use {
  font-size: 12px;
}
.mask-payment-item {
  margin-bottom: 50px;
  cursor: pointer;
}
.mask-payment-item .icon {
  width: 38px;
  height: 38px;
  margin-right: 38px;
}
</style>
<style>
.el-dropdown {
  font-size: 12px;
  cursor: pointer;
}
</style>
